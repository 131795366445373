import { render, staticRenderFns } from "./face.vue?vue&type=template&id=020e4f57&scoped=true&"
import script from "./face.vue?vue&type=script&lang=js&"
export * from "./face.vue?vue&type=script&lang=js&"
import style0 from "./face.vue?vue&type=style&index=0&id=020e4f57&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020e4f57",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/h5-web-gold/h5-web-gold/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('020e4f57')) {
      api.createRecord('020e4f57', component.options)
    } else {
      api.reload('020e4f57', component.options)
    }
    module.hot.accept("./face.vue?vue&type=template&id=020e4f57&scoped=true&", function () {
      api.rerender('020e4f57', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/faceAuthentication/faceProtocol/face.vue"
export default component.exports